import React, { ReactFragment, useState } from "react";
import { Link } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Main as Body } from "../components/site/Main";
import Wrapper from "../components/site/Wrapper";

const Header = styled.header`
  display: flex;
`;

const Logo = styled.div`
  min-height: 3rem;
  flex-grow: 1;
`;

const StyledLink = styled(Link)`
  margin: 0.5rem;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  margin: 0.5rem;
`;

const Main = styled.main`
  flex-grow: 1;
  margin: 1rem 0;
  border: 1px solid grey;
`;

const Footer = styled.footer`
  font-size: 0.9rem;
`;
const Nav = styled.nav`
  text-align: right;
  margin: 0 -0.5rem;
`;

export default function AppShellDemo() {
  return (
    <Wrapper>
    <PageWrapper>

      <Main>
        <article>
          <Body />
        </article>
      </Main>
    </PageWrapper>
    </Wrapper>
  );
}
