import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import WordFinder from "../WordFinder";
import { Puzzle } from "../puzzles/wordsearch/puzzle";
import { useWordSearch } from "../../hooks/useWordSearch";
import puzzle from "../../images/puzzle.png";



const MainWrapper = styled.div``;

const Top = styled.div`
  display: flex;
  align-items: top;
  flex-wrap: wrap;
`;

const CategoriesContainer = styled.div`
  display: flex;
  border: 1px dashed blue;
  flex-wrap: wrap;
  max-width: 100%;
`;

const CategoryGroup = styled.div`
  margin: 1rem;
  min-width: 25rem;
  background-color: #aa000022;
`;

const CategoryItem = styled.div`
  margin: 0.25rem;
`;

const ImageContainer = styled.div`
  min-height: 10rem;
  // min-width: 10rem;
  min-width: 450px;
  text-align: center;
  border: 1px solid red;
`;
const Content = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const items = [
  {
    location: "/puzzles/wordsearch/usa",
    name: "U.S.A",
    description: "Lorem ipsum",
    image: puzzle,
  },
  {
    location: "/puzzles/wordsearch/nature",
    name: "Nature",
    description: "Lorem ipsum",
    image: puzzle,
  },
  {
    location: "/puzzles/wordsearch/nationalparks1",
    name: "National Parks",
    description: "Lorem ipsum",
    image: puzzle,
  },
];

import {
  Image,
  Text,
  Container,
  ThemeIcon,
  Title,
  SimpleGrid,
  createStyles,
  Grid,
  Space,
} from "@mantine/core";
const supTitle = "PuzzleBase Games";
const description = `Proident mollit qui magna qui officia fugiat. Cupidatat occaecat
nulla nostrud ut amet ea velit. Enim esse enim excepteur incididunt
anim dolor est dolor aliquip. Aliqua laboris aliquip sit laboris id.
Veniam ea sunt elit aute velit dolore non nostrud commodo esse
aliquip mollit. Excepteur commodo laborum consectetur proident.
Consectetur consequat dolor ut ut elit officia dolore. Qui veniam
consectetur occaecat aliqua adipisicing. Nisi exercitation in mollit
proident ullamco sit voluptate voluptate proident. Reprehenderit
dolore proident pariatur et. Est excepteur culpa labore tempor duis
tempor ullamco irure incididunt ex.`;
const IMAGES = {
  auditors: puzzle,
  lawyers: puzzle,
};

const data = [
  {
    image: "auditors",
    title: "Nature",
    description:
      "Azurill can be seen bouncing and playing on its big, rubbery tail",
  },
  {
    image: "lawyers",
    title: "Lawyers",
    description: "Fans obsess over the particular length and angle of its arms",
  },
];
const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
  },

  item: {
    display: "flex",
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },

  supTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
      .color,
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: "center",
    marginTop: theme.spacing.xl,
  },

  description: {
    textAlign: "center",
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: "inline-block",
    color: theme.colorScheme === "dark" ? theme.white : "inherit",
  },
}));

export const Main = () => {
  const puzzleWords = ["one", "two", "three", "four"];
  const hiddenPuzzleWords = ["five"];

  const {
    words,
    updateWord,
    checkPoints,
    completedCount,
    hiddenWordsCompletedCount,
    hiddenWordsTotalCount,
    visibleWords,
    hiddenCompletedWords,
    visibleWordsTotalCount,

    wordSearch,
    defaultViewerProps,
    timer,
  } = useWordSearch(puzzleWords, undefined, hiddenPuzzleWords);

  const { classes } = useStyles();

  const gridItems = items.map((item) => (
    <div className={classes.item} key={item.name}>
      <ThemeIcon
        variant="light"
        className={classes.itemIcon}
        size={60}
        radius="md"
      >
        <Image src={item.image} />
      </ThemeIcon>

      <div>
        <Text weight={700} size="lg" className={classes.itemTitle}>
          {item.name}
        </Text>
        <Text color="dimmed">{item.description}</Text>
        <Link to={item.location}>{item.name} Word Search Puzzles</Link>
      </div>
    </div>
  ));

  return (
    <Container size={700} className={classes.wrapper}>
      <Text className={classes.supTitle}>{supTitle}</Text>
      <Container>
        <Title className={classes.title} order={2}>
          PuzzleBase online word searches and puzzle books
        </Title>
        <Space h="md" />
      </Container>
      <Grid columns={2}>
        <Grid.Col span={1}>
          <Image src={puzzle} />
        </Grid.Col>
        <Grid.Col span={1}>
          <Container size={660} p={0}>
            <Text color="dimmed">{description}</Text>
          </Container>
        </Grid.Col>
      </Grid>

      <SimpleGrid
        cols={2}
        spacing={50}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 30 }}
      >
        {gridItems}
      </SimpleGrid>
    </Container>
  );
};
